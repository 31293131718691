import * as React from 'react';

import '../scss/styles.scss';
import { Helmet } from 'react-helmet';

import Affiliate from '../components/Attribution/Affiliate';

// 20230711: repurpose for users who requested an welcome SMS on tiktok
// skip the flodesk modal on signup, redirect them to a sequin-university price page
const TikTok = () => {
  React.useEffect(() => {
    localStorage.setItem('from_tiktok', true);
  }, []);

  return (
    <>
      <Affiliate source="tiktok" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
};

export default TikTok;
